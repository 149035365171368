import { Icon } from "@lipihipi/rtc-ui-components";
import * as routePaths from "../../RouteConstant";
import { LMS_URL, Modules } from "../../constant";

export const MenuArr = [
  {
    id: crypto.randomUUID(),
    data: [
      {
        id: crypto.randomUUID(),
        icon: Icon.Dashboard,
        text: "Dashboard",
        active: true,
        link: routePaths.DASHBOARD,
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.UserLink,
        text: "Users & Role Management",
        active: true,
        modules: [Modules.Team, Modules.Role],
        child: [
          {
            id: crypto.randomUUID(),
            text: "User",
            link: routePaths.TEAM_LIST,
            active: true,
            modules: [Modules.Team],
          },
          {
            id: crypto.randomUUID(),
            text: "Role Management",
            link: routePaths.ROLE_LIST,
            modules: [Modules.Role],
            active: true,
          },
        ],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.User,
        text: "Clients",
        active: true,
        link: routePaths.CLIENT_LIST,
        modules: [Modules.Client],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.KnowledgeBank,
        text: "Knowledge Bank",
        active: true,
        link : routePaths.KNOWLEDGEBANK_LIST,
        modules: [Modules.KnowledgeBank]
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Lawyer,
        text: "Lawyers",
        active: true,
        link: routePaths.LAWYER_LIST,
        modules: [Modules.Lawyer],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Bookmark,
        text: "Client Packages",
        active: true,
        link: routePaths.PACKAGES_LIST,
        modules: [Modules.Package],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Document,
        text: "Legal Document",
        active: true,
        link: routePaths.LEGAL_DOCUMENT_LIST,
        modules: [Modules.LegalDocument],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Lawyer,
        text: "Court",
        active: true,
        link: routePaths.COURT_LIST,
        modules: [Modules.Court],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Dashboard,
        text: "Categories",
        active: true,
        link: routePaths.CATEGORY_LIST,
        modules: [Modules.Category],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Copy,
        text: "Section",
        active: true,
        link: routePaths.SECTION_LIST,
        modules: [Modules.Section],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Bookmark,
        text: "Subscription Package",
        active: true,
        link: routePaths.SUBSCRIPTIN_LIST,
        modules: [Modules.Subscription],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.LMS,
        text: "LMS",
        active: true,
        link: LMS_URL,
        modules: [Modules.LMS],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Document,
        text: "Document Template",
        active: true,
        modules: [Modules.DocumentTemplate],
        child: [
          {
            id: crypto.randomUUID(),
            text: "Attributes",
            link: routePaths.ATTRIBUTE_LIST,
            active: true,
            modules: Modules.Attribute,
          },
          {
            id: crypto.randomUUID(),
            text: "Type",
            link: routePaths.TYPE_LIST,
            active: true,
            modules: Modules.Type,
          },
          {
            id: crypto.randomUUID(),
            text: "Document Template",
            link: routePaths.DOCUMENT_TEMPLATE_LIST,
            active: true,
            modules: Modules.DocumentTemplate,
          },
        ],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.MapLocation,
        text: "City",
        active: true,
        link: routePaths.CITY_LIST,
        modules: [Modules.City],
      },

      {
        id: crypto.randomUUID(),
        icon: Icon.Review,
        text: "Review",
        active: true,
        link: routePaths.REVIEW_LIST,
        modules: [Modules.Review],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Pages,
        text: "Website Pages",
        active: true,
        link: routePaths.PAGE_LIST,
        modules: [Modules.Page],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.Subscriber,
        text: "Subscriber",
        active: true,
        link: routePaths.SUBSCRIBER_LIST,
        modules: [Modules.Subscriber],
      },
      {
        id: crypto.randomUUID(),
        icon: Icon.UrlSeo,
        text: "Redirect Url SEO",
        active: true,
        link: routePaths.REDIRECTURL_LIST,
        modules: [Modules.Subscriber],
      },
    ],
  },
];
